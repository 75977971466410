import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useUserStore } from '@/store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'User',
    component: () => import('../views/LayOut/indexView.vue'),
    redirect: '/user',
    children: [
      {
        path: '',
        name: 'User',
        component: () => import('../views/User/UserInfo.vue'),
        children: [],
        meta: { title: 'User' }
      }
    ]
  },
  {
    path: '/setting',
    name: 'Setting',
    component: () => import('../views/User/AccountSetting.vue'),
    meta: { title: 'Setting' }
  },
  {
    path: '/choosePlatform',
    name: 'ChoosePlatform',
    component: () => import('../views/User/ChoosePlatform.vue'),
    meta: { title: 'ChoosePlatform' }
  },
  {
    path: '/points',
    name: 'Points',
    component: () => import('../views/User/PointsDetails.vue'),
    meta: { title: 'Points' }
  },
  {
    path: '/platformInfo',
    name: 'PlatformInfo',
    component: () => import('../views/User/PlatformInfo.vue'),
    meta: { title: 'PlatformInfo' }
  },
  {
    path: '/linkTigo',
    name: 'LinkTigo',
    component: () => import('../views/User/LinkTigo.vue'),
    meta: { title: 'LinkTigo' }
  },
  {
    path: '/linkXES',
    name: 'LinkXES',
    component: () => import('../views/User/LinkXES.vue'),
    meta: { title: 'LinkXES' }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/User/AboutView.vue'),
    meta: { title: 'About' }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/User/settings/IndexView.vue'),
    meta: { title: 'Settings' },
    children: [
      {
        path: '',
        name: 'Settings',
        component: () => import('../views/User/settings/SettingsView.vue')
      },
      {
        path: '/language',
        name: 'Language',
        component: () => import('../views/User/settings/LanguageView.vue')
      },
      {
        path: '/notifications',
        name: 'Notifications',
        component: () => import('../views/User/settings/NotificationsView.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login/LoginView.vue'),
    meta: { title: 'Login' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const whiteList = ['Login']
router.beforeEach((to, from, next) => {
  // pinia的数据可能会发生变化
  const userStore = useUserStore()
  // 无token 路由拦截 重新登陆
  // if (!userStore.user?.token && !whiteList.includes(to.name as string)) {
  //   next({ name: 'Login' })
  // } else {
  //   next()
  // }
  next()
})

// 路由重定时进入
// router.afterEach((to) => {})

export default router
