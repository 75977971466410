import { defineStore } from 'pinia'
import type { User } from '@/types/User'
import { ref } from 'vue'

const useUserStore = defineStore(
  'User',
  () => {
    const user = ref<User>()
    const setUser = (newUser: User) => {
      user.value = newUser
    }
    const delUser = () => {
      user.value = undefined
    }
    return { user, setUser, delUser }
  },
  { persist: true }
)
export { useUserStore }
