import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  "data-theme": "dark",
  class: "container"
}


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

// import { ref } from 'vue'
// import { useI18n } from 'vue-i18n'
// const themeSelected = ref<string>('dark')

// const { locale } = useI18n()
// const changeLanguage = (type:string):void => {
//   locale.value = type
// }
// const changeBG = (theme: string):void => {
//   themeSelected.value = theme
//   document.documentElement.setAttribute('data-theme', theme)
// }


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view)
  ]))
}
}

})